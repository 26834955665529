@use "sass:math";

@use "../../../../styles/variables.module" as *;

.rowWrapper {
  //height: 100%;
  box-sizing: border-box;
  cursor: default;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 1;
  }
}

.rowWrapperDragDisabled {
  cursor: default;
}

.row {
  height: 100%;
  white-space: nowrap;
  display: flex;
  position: relative;
  width: 100%;

  & > * {
    box-sizing: border-box;
  }

  /**
 * The outline of where the element will go if dropped, displayed while dragging
 */
  &LandingPad {
    border: none;
    box-shadow: none;
    outline: none;

    * {
      opacity: 0 !important;
    }

    &::before {
      background-color: lightblue;
      border: 2px dotted black;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }

  /**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
  &CancelPad {
    @extend .rowLandingPad;

    &::before {
      background-color: #e6a8ad;
    }
  }

  /*
  Nodes matching the search conditions are highlighted
 */
  &SearchMatch {
    box-shadow: inset 0 -7px 7px -3px #0080ff;
  }

  /**
   The node that matches the search conditions and is currently focused
  **/
  &SearchFocus {
    box-shadow: inset 0 -7px 7px -3px #fc6421;
  }
}


%rowItem {
  display: inline-block;
  vertical-align: middle;
  background-color: white;
}

.rowContents {
  @extend %rowItem;
  position: relative;
  height: 100%;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 90%;
}

.rowLabel {
  @extend %rowItem;
  flex: 0 1 auto;
  line-height: 50px;
  height: 50px;
  width: 100%;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 500;
  overflow: hidden;
}

.rowToolbar {
  @extend %rowItem;
  flex: 0 1 auto;
  display: flex;
  margin-right: 10px;
}

.toolbarButton {
  @extend %rowItem;
}

.button {
  span {
    transition: transform .25s ease;
  }

  &Expanded, &Collapsed {
    appearance: none;
    border: none;
    background: transparent;
    padding: 0;
    z-index: 2;
    position: absolute;
    top: 40%;
    width: 30px;
    height: 30px;
    transform: translate3d(-50%, -50%, 0);
    cursor: pointer;
    color: $admin-blue-dark;
    font-size: 1.5rem;
    max-width: 10%;
    padding-left: 10px;

    &:hover {
      color: #333;
    }

    &:hover::after {
      border-top-color: black;
    }

    &:focus {
      outline: none;

      &::after {
        filter: drop-shadow(0 0 1px #83bef9) drop-shadow(0 0 1px #83bef9) drop-shadow(0 0 1px #83bef9);
      }
    }
  }

  &Expanded {
    span {
      transform: rotate(90deg);
    }
  }
}

/**
 * Line for under a node with children
 */
.lineChildren {
  height: 100%;
  display: inline-block;
}

/* ==========================================================================
   Scaffold

    Line-overlaid blocks used for showing the tree structure
   ========================================================================== */
.lineBlock {
  height: 100%;
  position: relative;
  display: inline-block;
  flex: 0 0 auto;
}

.absoluteLineBlock {
  @extend .lineBlock;
  position: absolute;
  top: 0;
}

/* Highlight line for pointing to dragged row destination
   ========================================================================== */
$highlight-color: #36c2f6;
$highlight-line-size: 6px; // Make it an even number for clean rendering

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.highlightLineVertical {
  z-index: 3;

  &::before {
    position: absolute;
    content: '';
    background-color: $highlight-color;
    width: $highlight-line-size;
    margin-left: math.div($highlight-line-size, -2);
    left: 50%;
    top: 0;
    height: 100%;
  }

  @keyframes arrow-pulse {
    $base-multiplier: 10;
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    30% {
      transform: translate(0, 30% * $base-multiplier);
      opacity: 1;
    }
    70% {
      transform: translate(0, 70% * $base-multiplier);
      opacity: 1;
    }
    100% {
      transform: translate(0, 100% * $base-multiplier);
      opacity: 0;
    }
  }

  &::after {
    content: '';
    position: absolute;
    height: 0;
    margin-left: -1 * $highlight-line-size * 0.5;
    left: 50%;
    top: 0;
    border-left: $highlight-line-size * 0.5 solid transparent;
    border-right: $highlight-line-size * 0.5 solid transparent;
    border-top: $highlight-line-size * 0.5 solid white;
    animation: arrow-pulse 1s infinite linear both;
  }
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |  |  |
 * +--+--+
 */
.highlightTopLeftCorner {
  &::before {
    z-index: 3;
    content: '';
    position: absolute;
    border-top: solid $highlight-line-size $highlight-color;
    border-left: solid $highlight-line-size $highlight-color;
    box-sizing: border-box;
    height: calc(50% + #{$highlight-line-size * 0.5});
    top: 50%;
    margin-top: math.div($highlight-line-size, -2);
    right: 0;
    width: calc(50% + #{$highlight-line-size * 0.5});
  }
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  +->|
 * +-----+
 */
.highlightBottomLeftCorner {
  $arrow-size: 7px;
  z-index: 3;

  &::before {
    content: '';
    position: absolute;
    border-bottom: solid $highlight-line-size $highlight-color;
    border-left: solid $highlight-line-size $highlight-color;
    box-sizing: border-box;
    height: calc(100% + #{$highlight-line-size * 0.5});
    top: 0;
    right: $arrow-size;
    width: calc(50% - #{$arrow-size - ($highlight-line-size * 0.5)});
  }

  &::after {
    content: '';
    position: absolute;
    height: 0;
    right: 0;
    top: 100%;
    margin-top: -1 * $arrow-size;
    border-top: $arrow-size solid transparent;
    border-bottom: $arrow-size solid transparent;
    border-left: $arrow-size solid $highlight-color;
  }
}


.moveHandle,
.loadingHandle {
  width: 20px;
  //background: #d9d9d9
  //url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+')
  //no-repeat center;
  //border: solid #aaa 1px;
  //box-shadow: 0 2px 2px -2px;
  cursor: move;
  border-radius: 1px;
  z-index: 1;
  text-align: right;
  color: #999;
  font-size: 1rem;
}

.loadingHandle {
  cursor: default;
  background: #d9d9d9;
}

.loadingCircle {
  width: 80%;
  height: 80%;
  margin: 10%;
  position: relative;
}
